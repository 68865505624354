<script>

export default {
  name: 'UsersManager',

  data() { return {
    startIndex: 0,
    waitRepsonse: false,
    loadMoreBnt: false,
    userList: [],
  }}, 

  methods: {
    OnLoadUserList({users, metadata})
    {
      this.userList = this.userList.concat(users);
      this.waitRepsonse = false;
      this.loadMoreBnt = metadata.user_list_length >= 20;

  },

    OnSearch()
    {
      if(this.startIndex == 0) {
        this.userList = [];
      }

      this.waitRepsonse = true;
      this.loadMoreBnt = false;
    },

    LoadMore()
    {
      this.startIndex += 20;
      this.Search();
    },

    SetStartIndex(value)
    {
      this.startIndex = value;
    },

    Search()
    {
      this.$refs.inputSearch?.Search({start: this.startIndex});
    },
    
  },

}
</script>


<template>
<div class="users-page">
  <div class="box box-list box-list-user">

    <div class="box-list-header header">
      <user-search-input 
        ref="inputSearch"
        :start="startIndex" 
        :search-on-mount="true" 
        @input="SetStartIndex(0)" 
        @search="OnSearch" 
        @result="OnLoadUserList">
      </user-search-input>
    </div>

    <div class="box-list-body user-list">
      <user-row v-for="(user, index) in userList" :key="index" :user-data="user">
      </user-row>

      <loading-box v-if="waitRepsonse"></loading-box>
      <div v-else-if="!userList.length && !waitRepsonse" class="user-row no-user-row">
        {{$lan('no_results')}}
      </div>

      <button class="border-btn" v-if="loadMoreBnt" @click="LoadMore">
        {{$lan('load_more_btn')}}
      </button>

    </div>
  </div>

</div>
</template>



<style scoped>
.users-page {
  justify-content: center;
}

.box-list-user {
  max-width: 700px;
  flex-grow: 1;
  animation: zoom-in 0.1s;
  height: 600px;
  margin-bottom: 20px;
  background-color: var(--box-color2);
}

.user-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 98%;
  height: 520px;
  margin: 5px;
  padding: 10px;
  gap: 5px;
}

.searchInput {
  width: 100%;
  margin-inline: 20px;
}

.load-btn {
  display: block;
  margin: auto;
  margin-block: 30px;
  height: 40px;
}

#groups-container {
  float: left;
  animation: zoom-in 0.1s;
}

.no-user-row {
  height: 40px;
  font-weight: bold;
  padding: 0px;
  line-height: 40px;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  height: fit-content;
  overflow: hidden;
  border-radius: 0 inherit 0 0;
}

@media only screen and (max-width: 1200px) {
  .box-list-user {
    max-width: none;
    width: 100%;
    margin-right: 0px;
  }
}
</style>

